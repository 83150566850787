/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url("./assets/fonts/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/fonts/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/fonts/CentraNo2-Book.ttf");
  font-weight: 400;
}

:root {
  --bdazzled-blue: #2c5784;
  --bistre: #251101;
  --glossy-grape: #a499be;
  --green-bright: #64ffda;
  --independence: #414361;
  --lightest-navy: #233554;
  --lightest-slate: #ccd6f6;
  --middle-blue-green: #99d5c9;
  --midnight: #5b1865;
  --oxford-blue: #05204a;
  --rich-black: #02020a;
  --slate: #8892b0;
  --spanish-bistre: #717744;
  --taupe-grey: #988b8e;
  --tyrian-purple: #470024;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* track */
::-webkit-scrollbar-track {
  background-color: var(--glossy-grape);
}

/* handle */
::-webkit-scrollbar-thumb {
  background-color: var(--midnight);
  border-radius: 3px;
}
/* handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--bdazzled-blue);
}

/* handle when clicked */
::-webkit-scrollbar-thumb:active {
  background-color: var(--bistre);
}

/************ Default CSS ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  /* background-color: #121212 !important; */
  background-color: var(--rich-black) !important;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  padding: 0;
  line-height: normal !important;
}

h4 {
  font-size: larger !important;
}

h6 {
  font-size: 0.67em !important;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar CSS ************/
#logo {
  height: 41px;
  width: auto;
}

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner CSS ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(to left, #654ea3, #eaafc8);
  /* background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%); */
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner a {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;

  text-decoration: none;
}

.banner a:hover {
  color: var(--glossy-grape);
}

.banner a:active {
  color: var(--tyrian-purple);
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.download-btn {
  padding-left: 0.5rem;
}

.banner img {
  /* animation: updown 3s linear infinite; */
  width: 11rem;
  padding-bottom: 0.5rem;
}

.banner .personal-info {
  display: grid;
  justify-items: start;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

@media only screen and (max-width: 600px) {
  .col-xl-5 {
    padding-top: 3rem;
  }
}

/************ Skills CSS ************/
.skill {
  padding: 80px 0;
  position: relative;
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  margin-top: -60px;
  padding: 0.75rem 0 1rem 0;
}

.skill-bx span,
.footer span {
  color: var(--middle-blue-green);
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
}

.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
  /* padding-bottom: 1rem; */
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.react-multiple-carousel__arrow--left {
  left: calc(0.25% + 1px) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(0.25% + 1px) !important;
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Experience CSS ************/
.experience {
  padding: 80px 0;
  position: relative;
  background-color: none;
}

.experience h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.experience p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  /* margin: 14px auto 30px auto; */
  width: 100%;
  padding-left: 3.5em;
}
.experience .nav.nav-pills {
  width: 75%;
  margin: 1.75em auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.experience .nav.nav-pills .nav-item {
  width: 50%;
}

.experience .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;

  float: left;
  display: inline;
}

.experience .nav.nav-pills .nav-link img {
  height: 40px;
  float: left;
}

.experience .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(
    to right,
    #eaafc8,
    #654ea3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.experience .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
/* .experience .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#exp-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#exp-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
} */

/************ Experience Tabs CSS ************/
.list-title {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: var(--lightest-slate);
}

.list-location {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  font-weight: bold;
}

.list-location a {
  color: var(--midnight);
}

.list-location a:hover {
  color: var(--glossy-grape);
}

.list-location a:active {
  color: var(--tyrian-purple);
}

.list-duration {
  font-family: "NTR", sans-serif;
  font-size: 18px;
  color: var(--slate);
}

#vertical-tabpanel {
  margin-top: -28px;
  padding-left: 25px;
  text-align: left !important;
}

.MuiTab-wrapper {
  text-align: left;
  align-items: flex-start !important;
  font-family: "NTR", sans-serif;
}

.MuiButtonBase-root {
  font-size: 16px !important;
  outline: none !important;
  color: var(--lightest-slate) !important;
}

.PrivateTabIndicator-colorSecondary-5 {
  background-color: var(--midnight) !important;
}
.jss5 {
  background-color: var(--midnight) !important;
}
.makeStyles-tabs-2 {
  border-right: 1px solid var(--lightest-navy) !important;
}
#experience .MuiTab-root {
  padding: 6px 20px;
}

.tab-content {
  padding-top: 2rem;
  display: flex;
}

[role="tabpanel"] {
  width: 100%;
  /* min-height: 55vh; */
}

.MuiButtonBase-root:focus {
  color: var(--midnight) !important;
}

#expBox {
  float: left;
  display: flex;
  flex-grow: 1;
  background-color: none;
}

.experience .MuiBox-root {
  padding: 0 !important ;
}

ul {
  list-style: none;
  margin-left: -40px;
}

.description li::before {
  content: "✜ ";
  color: var(--midnight);
  position: absolute;
  left: 0;
}

.description li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  font-size: 18px;
  color: var(--slate);
}

.description {
  padding-top: 24px;
  max-width: 650px;
  font-family: "NTR", sans-serif;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .description {
    padding-right: 1.5rem;
  }

  .list-title {
    font-size: 22px;
  }

  .list-location {
    font-size: 22px;
  }

  #expBox {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }

  .MuiTabs-root {
    margin-bottom: 2rem;
  }
}

/************ Projects CSS ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: none;
}
.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.3333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(to left, #654ea3, #eaafc8);
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
/* .project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
} */
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(to left, #654ea3, #eaafc8);
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx .clickable {
  cursor: pointer;
}

.proj-txtx h4 {
  font-size: 30px !important;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.proj-txtx h4:hover {
  font-size: 34px !important;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.link-icons a {
  width: 20px;
  /* height: 20px; */
  display: inline-flex;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

#projects .background-image-left {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

#game_section {
  padding-bottom: 3.5rem;
}

#info {
  display: flex;
  justify-content: center;
}

#info a {
  display: flex;
  justify-content: center; /* horizontal center of parent div */
  align-items: center; /* vertical center of div */
  color: #fff;
  width: fit-content;
}

#info a:hover div {
  color: var(--glossy-grape);
}

#info a:hover svg path {
  fill: var(--glossy-grape);
}

#info a svg {
  margin: 0 1rem 0 0;
  height: 1.75rem;
  width: auto;
}

/* phones */
@media only screen and (max-width: 600px) {
  .MuiModal-root .MuiBox-root {
    height: 85vh !important;
    width: 84.01vw !important;
    transform: translate(-50%, -50%) !important;
  }
}

/* tablets */
@media only screen and (max-width: 1024px) {
  .MuiModal-root .MuiBox-root {
    height: 75vh !important;
    width: 90vw !important;
    transform: translate(-50%, -48%) !important;
  }
}

/************ Project card CSS ************/
#modal {
  height: 100%;
}

.modal-header {
  padding-top: 20px;
}

.modal-header h4 {
  padding-left: 1.25rem;
}

.modal-header .link-icons {
  display: flex;
  align-content: center;
}

.basic-info {
  display: inline-flex;
  align-items: center;
}

.basic-info img {
  width: 50px;
  height: auto;
}

.modal-content {
  display: inline;
  height: calc(83% + 1vh);
  /* max-height: 80vh;
    overflow-y: hidden; */
  font-size: 16px;
}

#content {
  height: 100%;
  overflow-y: auto;
}

#content p {
  max-height: 51.04vh;
  margin-bottom: 0;
}

#content li::before {
  content: "➥ ";
}

/* width */
#content::-webkit-scrollbar {
  background-color: #b8b8b8;
  width: 5px;
}

/* track */
#content::-webkit-scrollbar-track {
  background-color: #0a1929;
}

/* handle */
#content::-webkit-scrollbar-thumb {
  background-color: #215286;
  border-radius: 3px;
}
/* handle on hover */
#content::-webkit-scrollbar-thumb:hover {
  background-color: #b8b8b8;
}

/* handle when clicked */
#content::-webkit-scrollbar-thumb:active {
  background-color: #7e7c7c;
}

@media only screen and (max-width: 600px) {
  .modal-content {
    max-height: 70vh;
  }

  #content {
    max-height: calc(inherit + 5vh);
  }
}

/************ Footer CSS ************/
.footer {
  display: flex;
  align-items: center;
}

#credits {
  margin: 100px 0 0 0 !important;
  font-size: 16px;
  text-align: center;
  width: 100%;
}

#credits a {
  text-decoration: none;
  color: #aa367c;
}

#credits a:hover {
  color: #be5996;
}

#credits a:active {
  color: #711b4f;
}
